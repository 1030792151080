// @flow
// import $ from 'jquery';
import parseURL from 'url-parse';
import Swiper from 'swiper';
// import 'slick-carousel';

const selectorRoot = '.o-content-overlay-gallery';

/**
 * Ini slick gallery for this component ...
 * @param {HTMLElement} root The iframe root element
 */
function iniGallery(root: HTMLElement) {
	const gallerySlideshow = root.querySelector(
		'.o-content-overlay-gallery__gallery'
	);
	const prevArrow = root.querySelector('.m-control-slider__prev');
	const nextArrow = root.querySelector('.m-control-slider__next');
	const currentCount = root.querySelector(
		'.m-control-slider__pagination-current'
	);
	const totalCount = root.querySelector('.m-control-slider__pagination-total');

	if (!prevArrow || !nextArrow || !currentCount || !totalCount) {
		throw new Error('Missing elements to initialize slider');
	}

	function updateCount(currentSlide: number, totalSlides: number) {
		currentCount.innerHTML = String(currentSlide).padStart(2, '0');
		totalCount.innerHTML = String(totalSlides).padStart(2, '0');
	}

	const swiper = new Swiper(gallerySlideshow, {
		// Optional parameters
		direction: 'horizontal',
		loop: true,
		speed: 600,
		slidesPerView: 1,
		longSwipesRatio: 0.2,

		// lazyload
		lazy: true,

		// Navigation arrows
		navigation: {
			prevEl: prevArrow,
			nextEl: nextArrow,
		},

		on: {
			init() {
				// because of we are using loop: true, total length is - 2
				updateCount(this.realIndex + 1, this.slides.length - 2);
			},
			slideChange() {
				// because of we are using loop: true, total length is - 2
				updateCount(this.realIndex + 1, this.slides.length - 2);
			},
		},
	});

	// const $gallerySlideshow = $(root).first(
	// 	'.o-content-overlay-gallery__gallery'
	// );
	// const $prevArrow = $(root).find('.m-control-slider__prev');
	// const $nextArrow = $(root).first('.m-control-slider__next');
	// const $currentCount = $(root).first('.m-control-slider__pagination-current');
	// const $totalCount = $(root).first('.m-navigation-gallery__pagination-total');

	// const url = parseURL(window.location.href);
	// console.log(url);

	// function updateCount(currentSlide: number, totalCount: number) {
	// 	$currentCount.html(String(currentSlide).padStart(2, '0'));
	// 	$totalCount.html(String(totalCount).padStart(2, '0'));
	// }

	// $gallerySlideshow.on('init', (event, slick) => {
	// 	updateCount(slick.currentSlide + 1, slick.slideCount);
	// });

	// $gallerySlideshow.on('beforeChange', (event, slick, current) => {
	// 	updateCount(current + 1, slick.slideCount);
	// });

	// $gallerySlideshow.slick({
	// 	infinite: true,
	// 	slidesToShow: 1,
	// 	slidesToScroll: 1,
	// 	mobileFirst: true,
	// 	variableWidth: true,
	// 	prevArrow: $prevArrow,
	// 	nextArrow: $nextArrow,
	// 	speed: 600,
	// 	initialSlide: 0,
	// });
}

export default function ini(root: HTMLElement | Document = document) {
	root.querySelectorAll(selectorRoot).forEach(element => {
		iniGallery(element);
	});
}
