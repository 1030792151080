// @flow
import Swiper from 'swiper';
// import breakpoints from './../../assets/js/breakpoints';

const selectorRoot = '.o-section-events';

/**
 * Ini slick gallery for this component ...
 * @param {HTMLElement} root The iframe root element
 */
function iniGallery(root: HTMLElement) {
	const gallerySlideshow = root.querySelector('.o-section-events__slider');

	const swiper = new Swiper(gallerySlideshow, {
		// Optional parameters
		slidesPerView: 'auto',
		spaceBetween: 30,
		freeMode: true,
		// slideActiveClass: 'active',
	});
}

export default function ini(root: HTMLElement | Document = document) {
	root.querySelectorAll(selectorRoot).forEach(element => {
		iniGallery(element);
	});
}
